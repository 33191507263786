// src
import Config from '@/config'

// Classes
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'
import { BuilderManager }    from '@/Classes/Responses/BuilderManager'
import { ParseItemsDetailFormattersObject } from '@/Classes/Responses/BuilderManager'
import { PrimitiveTools }    from '@/Classes/Static/PrimitiveTools'
import { RutTools }          from '@/Classes/Static/RutTools'

// Constants
import { AppValues } from '@/Constants/Global/AppValues'
import { Documents } from '@/Constants/Global/Documents'

// Store
import Store from '@/Store/Global/Default'

// Namespace Export
export namespace DataParsers {
	export class Companies {
		static GetSummaryParser(stackedOrDefault: boolean) {
			if (stackedOrDefault) {
				return (data: Array<any>) => {
					// Clase Constructora.
					const bm = new BuilderManager(data)
					const { fields, items } = bm.getElements()
				
					// Eliminar los campos que no queremos mostrar en la tabla.
					bm.removeFields(fields, [
						'_idAdminCompany', '_idCompany', 'address', 'code', 'commune', 'isClient', 'isValid', 'region', 'type'
					])
				
					// Aplicar opciones a los campos.
					bm.parseFieldsOptions(fields, new FieldsOptions()
						.add('rut', 'Rut').isSortable().showDetailsButton()
						.add('name', 'Razón Social').isSortable()
					)
				
					// Definir los formatos que serán aplicados al valor de la celda.
					bm.parseItemsFormatters(items, {
						rut: (v: string) => {
							return v === '' ? AppValues.Strings.DEFAULT_EMPTY_STRING : RutTools.formatter(v)
						}
					})

					// Definir los campos a mostrar en el Detalle.
					bm.parseItemsDetail(items, {
						['Dirección']: ({ address }) => {
							return address
						},
						['Comuna']: ({ commune }) => {
							return commune
						},
						['Región']: ({ region }) => {
							return region
						},
						['Fono']: ({ phone }) => {
							return PrimitiveTools.Arrays.isInvalidOrEmpty(phone) ? AppValues.Strings.DEFAULT_EMPTY_STRING : phone
						}
					})
				
					// Retornar Objeto con las propiedades.
					return { fields, items }
				}
			}
			else {
				return (data: Array<any>) => {
					// Clase Constructora.
					const bm = new BuilderManager(data)
					const { fields, items } = bm.getElements()
				
					// Eliminar los campos que no queremos mostrar en la tabla.
					bm.removeFields(fields, ['_idAdminCompany', '_idCompany', 'code', 'isClient', 'isValid', 'storages', 'type'])
				
					// Aplicar opciones a los campos.
					bm.parseFieldsOptions(fields, new FieldsOptions()
						.add('rut', 'Rut').isSortable()
						.add('name', 'Razón Social').isSortable()
						.add('address', 'Dirección').isSortable()
						.add('commune', 'Comuna').isSortable()
						.add('region', 'Región').isSortable()
						.add('phone', 'Fono').isSortable()
					)
				
					// Aplicar un valor a los campos e items si no estan presentes.
					bm.parseItemsMissingFields(items, new ItemMissingFields()
						.add('address')
						.add('commune')
						.add('region')
						.add('phone')
					)
				
					// Definir los formatos que serán aplicados al valor de la celda.
					bm.parseItemsFormatters(items, {
						rut: (v: string) => {
							return v === '' ? AppValues.Strings.DEFAULT_EMPTY_STRING : RutTools.formatter(v)
						},
				
						region: (v: string, item: any) => {
							item.$region = v
							return PrimitiveTools.Strings.toLimitedLength(v.replace(/Región/g, 'R.'), 132)
						}
					})
				
					// Retornar Objeto con las propiedades.
					return { fields, items }
				}
			}
		}
	}

	export class Equipments {
		static SummaryParser(data: Array<any>) {
			// Clase Constructora.
			const bm = new BuilderManager(data)
			const { fields, items } = bm.getElements()
		
			// Eliminar los campos que no queremos mostrar en la tabla.
			bm.removeFields(fields, ['_idCompany', '_idStorage', '_idEquipment', 'hourmeter', 'company', 'status','group','serie','year','storageName','isValid'])
		
			// Aplicar opciones a los campos.
			bm.parseFieldsOptions(fields, new FieldsOptions()
				.add('code', 'Código').isSortable()
				.add('name', 'Descripción').isSortable()
				.add('mark', 'Marca').isSortable()
				.add('typeArticle', 'Tipo Artículo').isSortable()
				.add('groupArticle', 'Grupo Artículo').isSortable()
				.add('typeEquipment', 'Tipo Equipo').isSortable()
				.add('groupEquipment', 'Grupo Equipo').isSortable()
				.add('companyName', 'Compañia').isSortable()
			)
		
			// Aplicar un reordenamiento a los campos.
			bm.rearrangeFields(fields, ['code', 'name','serie', 'mark', 'typeEquipment', 'typeArticle', 'groupArticle','groupEquipment', 'year'])
		
			// Aplicar un valor a los campos e items si no estan presentes.
			bm.parseItemsMissingFields(items, new ItemMissingFields()
				.add('mark')
				.add('serie')
				.add('year')
				.add('groupEquipment')
				.add('typeArticle')			
			)
		
			// Retornar Objeto con las propiedades.
			return { fields, items }
		}
	}

	export class Storages {
		static GetSummaryParser(stackedOrDefault: boolean) {
			if (stackedOrDefault) {
				return (data: Array<any>, _actions?: any) => {
					// Clase Constructora.
					const bm = new BuilderManager(data)
					const { fields, items, actions } = bm.getElements()
				
					// Mostrar unicamemte los campos que queremos en la tabla.
					bm.showFields(fields, ['code', 'name'])
				
					// Aplicar opciones a los campos.
					bm.parseFieldsOptions(fields, new FieldsOptions()
						.add('code', 'Código').isSortable().setAlign(null, 'center').showDetailsButton()
						.add('name', 'Nombre').isSortable()
					)
				
					// Aplicar un nuevo Orden a todas las columnas visibles.
					bm.rearrangeFields(fields, ['code', 'name'])

					// Definir los campos a mostrar en el Detalle.
					const formatters: ParseItemsDetailFormattersObject = {
						['Código Planta']: ({ codeLocation }) => {
							return codeLocation
						},
						['Dirección']: ({ address }) => {
							return address
						},
						['Cliente']: ({ _idCompany }) => {
							const company = Store.getters.getStoredCompanyById(_idCompany)
							return company ? company.name : AppValues.Strings.DEFAULT_EMPTY_STRING
						},
						['Técnico']: ({ _idTechnical }) => {
							const user = Store.getters.getStoredUserById(_idTechnical)
							return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
						},
						['Adm. Contrato']: ({ _idUserAdmin }) => {
							const user = Store.getters.getStoredUserById(_idUserAdmin)
							return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
						}
					}
					if (_actions !== undefined) formatters['Acciones'] = _actions
					bm.parseItemsDetail(items, formatters)
				
					// Retornar Objeto con las propiedades.
					return { fields, items, actions }
				}
			}
			else {
				return (data: Array<any>, _actions?: any) => {
					// Clase Constructora.
					const bm = new BuilderManager(data, _actions)
					const { fields, items, actions } = bm.getElements()
				
					// Eliminar los campos que no queremos mostrar en la tabla.
					bm.removeFields(fields, [
						'_id', '_idCompany', '_idStorage', '_idTechnical', '_idUserAdmin', 'codeLocation', 'contacts', 'equipments', 'commune', 'region'
					])
				
					// Aplicar opciones a los campos.
					bm.parseFieldsOptions(fields, new FieldsOptions()
						.add('code', 'Código').isSortable().setAlign(null, 'center')
						// .add('codeLocation', 'Código Planta').isSortable().setAlign(null, 'center')
						.add('name', 'Nombre').isSortable()
						.add('address', 'Dirección').isSortable()
						.add('companyName', 'Cliente').isSortable()
						.add('technicalName', 'Técnico').isSortable().setAlign(null, 'center')
						.add('userAdminName', 'Adm. Contrato').isSortable().setAlign(null, 'center')
						.add('isValid', '¿Esta Activa?').isSortable().setAlign(null, 'center')
					)
				
					// Aplicar un nuevo Orden a todas las columnas visibles.
					bm.rearrangeFields(fields, [
						'code', 'codeLocation', 'name', 'address', 'companyName', 'technicalName', 'userAdminName', 'isValid'
					])
				
					// Aplicar un valor a los campos e items si no estan presentes.
					bm.parseItemsMissingFields(items, new ItemMissingFields()
						.add('address')
						.add('companyName')
						.add('technicalName')
						.add('userAdminName')
					)
				
					// Definir los formatos que serán aplicados al valor de la celda.
					bm.parseItemsFormatters(items, {
						isValid: (v: boolean) => {
							return v ? 'Sí' : 'No'
						}
					})
				
					// Retornar Objeto con las propiedades.
					return { fields, items, actions }
				}
			}
		}
	}

	export class Users {
		static SummaryExternalsParser(data: Array<any>) {
			// Clase Constructora.
			const bm = new BuilderManager(data)
			const { fields, items } = bm.getElements()
		
			// Eliminar los campos que no queremos mostrar en la tabla.
			bm.removeFields(fields, [
				'_idUser', '_idCompany', '_idRole', '_idWorkArea', 'mLastName', 'pLastName', 'permissions', 'status'
			])
		
			// Aplicar opciones a los campos.
			bm.parseFieldsOptions(fields, new FieldsOptions()
				.add('rut', 'Rut').isSortable().setAlign(null, 'center')
				.add('name', 'Nombre').isSortable()
				.add('email', 'Correo').isSortable()
				.add('phone', 'Fono').isSortable()
				.add('storages', 'Ubicaciones').isSortable()
			)
		
			// Aplicar un nuevo Orden a todas las columnas visibles.
			bm.rearrangeFields(fields, [
				'rut', 'name', 'email', 'phone'
			])
		
			// Definir los formatos que serán aplicados al valor de la celda.
			bm.parseItemsFormatters(items, {
				name: (_: void, item: any) => {
					return `${ item.name } ${ item.pLastName }`
				},
		
				phone: (_: void, item: any) => {
					return PrimitiveTools.Arrays.isInvalidOrEmpty(item.phone) ? AppValues.Strings.DEFAULT_EMPTY_STRING : item.phone
				},
		
				storages: (values: Array<ObjectId>) => {
					return values?.length > 0 ? values.map<string>((_idStorage) => {
						const storage = Store.getters.getStoredStorageById(_idStorage)
						return storage ? `${ storage.code } ${ storage.name }` : AppValues.Strings.DEFAULT_EMPTY_STRING
					}) : AppValues.Strings.DEFAULT_EMPTY_STRING
				}
			})
		
			// Retornar Objeto con las propiedades.
			return { fields, items }
		}

		static GetSummaryInternalsParser(stackedOrDefault: boolean) {
			if (stackedOrDefault) {
				return (data: Array<any>) => {
					// Clase Constructora.
					const bm = new BuilderManager(data)
					const { fields, items } = bm.getElements()
				
					// Mostrar unicamemte los campos que queremos en la tabla.
					bm.showFields(fields, ['rut', 'name'])
				
					// Aplicar opciones a los campos.
					bm.parseFieldsOptions(fields, new FieldsOptions()
						.add('rut', 'Rut').isSortable().setAlign(null, 'center').showDetailsButton()
						.add('name', 'Nombre').isSortable()
					)
				
					// Aplicar un nuevo Orden a todas las columnas visibles.
					bm.rearrangeFields(fields, ['rut', 'name'])
				
					// Eliminar los items que no queremos mostrar en la tabla.
					bm.removeItems(items, [
						{ if: Config.entries.removeRootUser, field: '_idRole', value: Documents.Roles.Kreis.ROOT },
						{ field: '_idRole', value: Documents.Roles.Kreis.CLIENT }
					])
				
					// Definir los formatos que serán aplicados al valor de la celda.
					bm.parseItemsFormatters(items, {
						name: (v: string, item: any) => {
							return `${ v } ${ item.pLastName }`
						},
					})

					// Definir los campos a mostrar en el Detalle.
					bm.parseItemsDetail(items, {
						['Correo']: ({ email }) => {
							return email
						},
						['Fono']: ({ phone }) => {
							return PrimitiveTools.Arrays.isInvalidOrEmpty(phone) ? AppValues.Strings.DEFAULT_EMPTY_STRING : phone
						},
						['Rol']: ({ _idRole }) => {
							const role = Store.getters.getStoredRoleById(_idRole)
							return role ? role.name : AppValues.Strings.DEFAULT_EMPTY_STRING
						},
						['Area Trabajo']: ({ _idWorkArea }) => {
							const workarea = Store.getters.getStoredAllWorkAreaById(_idWorkArea)
							return workarea ? workarea.name : AppValues.Strings.DEFAULT_EMPTY_STRING
						}
					})
				
					// Retornar Objeto con las propiedades.
					return { fields, items }
				}
			}
			else {
				return (data: Array<any>) => {
					// Clase Constructora.
					const bm = new BuilderManager(data)
					const { fields, items } = bm.getElements()
				
					// Eliminar los campos que no queremos mostrar en la tabla.
					bm.removeFields(fields, [
						'_idUser', '_idCompany', '_idStorage', '_idRole', '_idWorkArea', 'isValid', 'pLastName', 'mLastName', 'company', 'storages', 'permissions'
					])
				
					// Aplicar opciones a los campos.
					bm.parseFieldsOptions(fields, new FieldsOptions()
						.add('rut', 'Rut').isSortable().setAlign(null, 'center')
						.add('name', 'Nombre').isSortable()
						.add('email', 'Correo').isSortable()
						.add('phone', 'Fono')
						.add('role', 'Rol').isSortable()
						.add('workarea', 'Área').isSortable()
					)
				
					// Aplicar un nuevo Orden a todas las columnas visibles.
					bm.rearrangeFields(fields, [
						'rut', 'name', 'email', 'phone', 'role', 'workarea'
					])
				
					// Aplicar un valor a los campos e items si no estan presentes.
					bm.parseItemsMissingFields(items, new ItemMissingFields()
						.add('role')
						.add('workarea')
					)
				
					// Eliminar los items que no queremos mostrar en la tabla.
					bm.removeItems(items, [
						{ if: Config.entries.removeRootUser, field: '_idRole', value: Documents.Roles.Kreis.ROOT },
						{ field: '_idRole', value: Documents.Roles.Kreis.CLIENT }
					])
				
					// Definir los formatos que serán aplicados al valor de la celda.
					bm.parseItemsFormatters(items, {
						name: (v: string, item: any) => {
							return `${ v } ${ item.pLastName }`
						},
				
						role: (_: void, item: any) => {
							const { _idRole } = item
							const role = Store.getters.getStoredRoleById(_idRole)
							return role ? role.name : AppValues.Strings.DEFAULT_EMPTY_STRING
						},
				
						phone: (_: void, item: any) => {
							return PrimitiveTools.Arrays.isInvalidOrEmpty(item.phone) ? AppValues.Strings.DEFAULT_EMPTY_STRING : item.phone
						},
				
						workarea: (_: void, item: any) => {
							const { _idWorkArea } = item
							const workarea = Store.getters.getStoredAllWorkAreaById(_idWorkArea)
							return workarea ? workarea.name : AppValues.Strings.DEFAULT_EMPTY_STRING
						}
					})
				
					// Retornar Objeto con las propiedades.
					return { fields, items }
				}
			}
		}
	}

	export class WorkAreas {
		static SummaryParser(data: Array<any>) {
			// Clase Constructora.
			const bm = new BuilderManager(data)
			const { fields, items } = bm.getElements()
		
			// Eliminar los campos que no queremos mostrar en la tabla.
			bm.removeFields(fields, ['_idWorkArea', '_idLeader', 'status'])
		
			// Aplicar opciones a los campos.
			bm.parseFieldsOptions(fields, new FieldsOptions()
				.add('name', 'Nombre').isSortable()
				.add('leader', 'Líder').isSortable()
			)
		
			// Aplicar un nuevo Orden a todas las columnas visibles.
			bm.rearrangeFields(fields, ['name', 'leader'])
		
			// Aplicar un valor a los campos e items si no estan presentes.
			bm.parseItemsMissingFields(items, new ItemMissingFields()
				.add('leader')
			)
		
			// Definir los formatos que serán aplicados al valor de la celda.
			bm.parseItemsFormatters(items, {
				leader: (_: void, item: any) => {
					const { _idLeader } = item
					const user = Store.getters.getStoredUserById(_idLeader)
					return user ? `${ user.name } ${ user.pLastName }` : AppValues.Strings.DEFAULT_EMPTY_STRING
				}
			})
		
			// Retornar Objeto con las propiedades.
			return { fields, items }
		}
	}
}